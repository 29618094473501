<template>
  <div class="header-between flex-item flex-end">
    <!-- <div class="coin-box" v-if="address">{{balance || '--'}}USDT</div> -->
    <div class="wallet-box ml" v-if="address">
      {{
        address.substring(0, 6) +
          "..." +
          address.substring(address.length - 4, address.length)
      }}
    </div>
    <div class="wallet-box ml btn text-ellipsis" v-else @click="initWallet">
      {{ $t("鏈接錢包") }}
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, computed, watch } from "vue";
import { useStore } from "vuex";
import web3Data from "@/utils/initWeb3";
import tokenAbi from "@/utils/abi";
import { config } from "@/utils/config";
import BigNumber from "bignumber.js";
export default {
  setup() {
    const store = useStore();
    const data = reactive({
      address: computed(() => store.state.accounts[0]),
      web3: computed(() => store.state.provider),
      balance: "",
      initWallet: () => {
        if (!data.address) {
          web3Data.initWeb3();
        }
      },
      getBalance: async () => {
        let USDQContract = new data.web3.eth.Contract(
          tokenAbi,
          config.USDQAddress
        );
        let result = await USDQContract.methods.balanceOf(data.address).call();
        data.balance = new BigNumber(result).dividedBy(10 ** 18).toFixed(2, 1);
      }
    });
    if (data.address) {
      data.getBalance();
    }
    watch(
      () => data.address,
      val => {
        if (val) {
          data.getBalance();
        }
      }
    );
    const refData = toRefs(data);
    return {
      ...refData
    };
  }
};
</script>
