<template>
  <div class="topbg-wrapper">
    <div class="top-bg">
      <div class="common-page--menu">
        <div class="link link-white" @click="$router.push({ name: 'Index' })">
          {{$t("首頁")}}&nbsp;>&nbsp;
        </div>
        <div class="link link-white" @click="$router.replace({ name: 'MiningLp', query: { type: '1' } })" v-if="type == 1">
          {{ $t("LP挖礦") }}&nbsp;>&nbsp;
        </div>
        <div class="link link-white" @click="$router.replace({ name: 'MiningLp', query: { type: '2' } })" v-else>
          {{ $t("單幣挖礦") }}&nbsp;>&nbsp;
        </div>
        <div class="link">{{$t("贖回")}}</div>
      </div>
      <div class="top-header flex-middle">
        <div class="header-between flex-item"></div>
        <div class="header-center">
          {{ $t("贖回") }} {{ type === "1" ? `${name} LP` : name }}
        </div>
        <wallet-contract></wallet-contract>
      </div>
    </div>
    <!-- LP -->
    <el-form class="from-box">
      <div class="input-suffix flex-between">
        <span class="input-label">{{ $t("質押中數量：") }}</span>
        <el-input class="flex" :value="LPInfo.balances || '--'" readonly>
          <template #suffix>
            <span class="suffix-span">{{ type === "1" ? "LP" : name }}</span>
          </template>
        </el-input>
      </div>
      <div class="input-suffix flex-between nocenter" v-if="type === '2'">
        <span class="input-label">{{ $t("解押數量：") }}</span>
        <div class="flexs">
          <el-input
            :placeholder="$t('請輸入解押數量')"
            class="flex"
            v-model="amount"
            type="number"
          >
            <template #suffix>
              <span class="suffix-span">{{ type === "1" ? "LP" : name }}</span>
            </template>
          </el-input>
        </div>
      </div>
      <div class="input-suffix flex-between nocenter" v-else>
        <span class="input-label">{{ $t("解押比例：") }}</span>
        <div class="flexs">
          <el-input
            :placeholder="$t('請選擇解押比例')"
            class="flex"
            v-model="amounts"
            type="number"
            readonly
          >
            <template #suffix>
              <span class="suffix-span">%</span>
            </template>
          </el-input>
          <div class="sliders">
            <el-slider
              v-model="amounts"
              :step="25"
              @change="getSlider(val)"
              :marks="marks"
              show-stops
            />
          </div>
        </div>
      </div>

      <div class="input-suffix flex-between">
        <span class="input-label">{{ $t("剩餘數量：") }}</span>
        <el-input class="flex" :value="remainAmount" readonly>
          <template #suffix>
            <span class="suffix-span">{{ type === "1" ? "LP" : name }}</span>
          </template>
        </el-input>
      </div>

      <div class="form-btn">
        <!-- <el-button
          v-if="!address"
          type="primary"
          block
          round
          @click="initWallet"
          >鏈接錢包</el-button
        > -->
        <!-- v-else -->
        <div class="flex">
          <el-button
            type="primary"
            block
            round
            class="close"
            @click="$router.go(-1)"
            >{{ $t("返回") }}</el-button
          >
          <el-button
            type="primary"
            block
            round
            :disabled="loading"
            :loading="loading"
            @click="toWithdraw"
            >{{ $t("贖回") }}</el-button
          >
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { reactive, toRefs, computed, watch } from "vue";
import WalletContract from "@/components/WalletContract";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import LPStake from "@/utils/LPStake";
import { config } from "@/utils/config";
import BigNumber from "bignumber.js";
import tokenAbi from "@/utils/abi";
import web3Data from "@/utils/initWeb3";
import { ElMessage } from "element-plus";
import { MaxUint256 } from "@ethersproject/constants";
import { useI18n } from "vue-i18n";
export default {
  components: {
    WalletContract
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const route = useRoute();
    const data = reactive({
      BigNumber: BigNumber,
      type: route.params.type,
      name: route.params.name,
      tokenAddress: route.params.token,
      web3: computed(() => store.state.provider),
      address: computed(() => store.state.accounts[0]),
      LPStakeContract: null,
      tokenContract: null,
      LPInfo: {},
      amount: "",
      amounts: [0, 25, 50, 75, 100],
      marks: {
        0: "0",
        25: "25%",
        50: "50%",
        75: "75%",
        100: "100%"
      },
      loading: false,
      remainAmount: computed(() => {
        let remainAmount = "0";
        if (data.LPInfo.balances) {
          if (data.amount && new BigNumber(data.amount).gt(0)) {
            remainAmount = new BigNumber(data.LPInfo.balances).gte(data.amount)
              ? new BigNumber(data.LPInfo.balances)
                  .minus(data.amount)
                  .toFixed(6, 1)
              : 0;
          } else {
            remainAmount = data.LPInfo.balances;
          }
        }
        return remainAmount;
      }),
      initWallet: () => {
        if (!data.address) {
          web3Data.initWeb3();
        }
      },
      initContract: () => {
        data.LPStakeContract = new data.web3.eth.Contract(
          LPStake,
          config.LPStake
        );
        data.tokenContract = new data.web3.eth.Contract(
          tokenAbi,
          data.tokenAddress
        );
        data.initData();
      },
      initData: () => {
        data.getInfo();
      },
      getInfo: async () => {
        let decimals = await data.tokenContract.methods.decimals().call();
        data.LPInfo.tokenDecimals = decimals;

        let result = await data.LPStakeContract.methods
          .userInfo(data.address, data.tokenAddress)
          .call();
        data.LPInfo.balances = new BigNumber(result.balances)
          .dividedBy(Math.pow(10, data.LPInfo.tokenDecimals))
          .toFixed(6, 1);

        console.log(result);
        console.log(data.LPInfo);
      },
      toWithdraw: () => {
        if (!data.amount || new BigNumber(data.amount).lte(0)) {
          ElMessage.error(t("請輸入質押數量"));
          return;
        }
        if (new BigNumber(data.amount).gt(data.LPInfo.balances)) {
          ElMessage.error(t("數量不足"));
          return;
        }
        let amount = new BigNumber(data.amount)
          .multipliedBy(Math.pow(10, data.LPInfo.tokenDecimals))
          .toFixed();
        data.loading = true;
        console.log(data.tokenAddress, amount);
        data.LPStakeContract.methods
          .withdraw(data.tokenAddress, amount)
          .estimateGas({ from: data.address })
          .then(gas => {
            console.log("gas", gas);
            data.LPStakeContract.methods
              .withdraw(data.tokenAddress, amount)
              .send({ from: data.address, gas: gas })
              .then(() => {
                data.loading = false;
                data.initData();
              })
              .catch(() => {
                data.loading = false;
              });
          })
          .catch(err => {
            data.loading = false;
            console.log(err);
          });
      },
      getSlider: () => {
        data.amount = new BigNumber(data.LPInfo.balances)
          .multipliedBy(data.amounts / 100)
          .toFixed();
      }
    });
    if (data.address) {
      data.initContract();
    }
    watch(
      () => data.address,
      val => {
        if (val) {
          data.initContract();
        }
      }
    );
    const refData = toRefs(data);
    return {
      ...refData
    };
  }
};
</script>
<style lang="scss">
.topbg-wrapper {
  .el-slider__button {
    background: #fff url("~@/assets/image/slidersact.png") center center
      no-repeat;
    background-size: 19px 19px;
    border: none;
  }
  .el-slider__stop {
    background: none;
    background: #fff url("~@/assets/image/sliders.png") center center no-repeat;
    background-size: 12px 13px;
    width: 12px;
    height: 13px;
    vertical-align: middle;
    margin-top: -3px;
  }
}
</style>
<style lang="scss" scoped>
.nocenter {
  align-items: flex-start;
  margin-top: 40px;
  margin-bottom: 40px;
}
.sliders {
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 20px;
  line-height: 18px;
}

.address-list {
  max-width: 1400px;
  margin: 30px auto 80px;
}
.close {
  background: none !important;
  border: 1px solid #0956d0;
  color: #0956d0;
}
.bottom-table {
  background: #fff;
  border-radius: 20px;
  margin-top: 50px;
  .table-box {
    max-width: 1400px;
    margin: 0 auto;
  }
}
.flexs {
  flex: 1;
}
.from-box {
  padding: 35px 42px;
  margin-bottom: 50px;
}
.el-slider__marks-text {
}
@media screen and (max-width: 480px) {
  /deep/ .suffix-span {
    padding: 0 6px 0 0 !important;
    font-size: 15px !important;
    margin-right: -6px;
  }
  /deep/ .from-box .el-input__inner {
    // width: 150px !important;
    font-size: 14px !important;
    padding-right: 10px !important;
    background: #eff2f7;
    border: 0;
  }
  /deep/ .el-input-group__append,
  .el-input-group__prepend {
    padding: 0 7px 0 5px;
  }
  /deep/ .el-input__suffix {
    transform: translate(1, 0) !important;
  }
  /deep/ .from-box .input-suffix .suffix-span {
  }
  /deep/ .from-box .input-suffix .input-label {
    margin-right: 0;
  }
  /deep/ .from-box {
    margin-top: -150px;
  }
  /deep/ .el-input--suffix .el-input__inner {
    padding-right: 45px;
  }
  .from-box {
    padding: 0 15px;
  }
  /deep/ .el-input-group__append,
  .el-input-group__prepend {
    background: #eff2f7;
    border: 0;
  }
  /deep/ .el-input-group {
    background: #eff2f7;
    border-radius: 6px;
  }
  .nocenter {
    align-items: flex-start;
    margin-top: 0;
    margin-bottom: 0;
  }
  .sliders {
    margin: -15px 0 10px;
  }
  .topbg-wrapper {
    /deep/ .el-slider__button {
      background: #fff url("~@/assets/image/wap/phonesli.png") center center
        no-repeat;
      background-size: 12px 12px;
      border: none;
      width: 12px;
      height: 12px;
    }
    /deep/ .el-slider__stop {
      background: none;
      background: #fff url("~@/assets/image/wap/phones.png") center center
        no-repeat;
      background-size: 9px 9px;
      width: 8px;
      height: 8px;
      vertical-align: middle;
      margin-top: -2.5px;
    }
    /deep/ .el-slider__runway {
      -webkit-tap-highlight-color: transparent;
      height: 2.5px;
    }
    /deep/ .el-slider__bar {
      height: 2.5px;
    }
    /deep/ .el-slider__button-wrapper {
      top: -17px;
    }
  }
}
</style>
